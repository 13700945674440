'use client';

import { useLingui } from '@lingui/react';
import { CircularProgress } from '@mui/material';
import { Button, type ButtonProps } from '@prismo-io/design-system';
import type { LanguageCodeT } from '@prismo-io/schemas';
import Link from 'next/link';
import { useCallback, useMemo, useState } from 'react';
import type { Application } from '../types/application';
import type { AppPaths, LinkProps } from '../types/link';
import { translate } from '../utils/translate';

type Props<App extends Application, Path extends AppPaths<App>> = LinkProps<
  App,
  Path
> &
  Omit<ButtonProps, 'href' | 'LinkComponent' | 'role'> & {
    prefetch?: boolean;
    replace?: boolean;
  } & {
    loadingOnClick?: boolean;
  };

export function ButtonLink<App extends Application, Path extends AppPaths<App>>(
  props: Props<App, Path>
) {
  const { i18n } = useLingui();

  const {
    app,
    path,
    params = undefined,
    queryParams = undefined,
    addHostname = false,
    locale = i18n.locale as LanguageCodeT,
    prefetch = true,
    replace = false,
    loadingOnClick = false,
    disabled = false,
    onClick = undefined,
    children,
    startIcon = undefined,
    ...rest
  } = props;

  const href = useMemo(
    () =>
      translate<App, Path>({
        app,
        path,
        params,
        queryParams,
        locale,
        addHostname,
      } as LinkProps<App, Path>),
    [app, path, params, queryParams, locale, addHostname]
  );

  const [loading, setIsLoading] = useState<boolean>(false);

  const onClickAugmented = useCallback(
    (e: any) => {
      if (loadingOnClick) {
        setIsLoading(true);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [loadingOnClick, onClick]
  );

  return (
    <Button
      {...rest}
      component={Link}
      href={href}
      prefetch={prefetch}
      replace={replace}
      onClick={onClickAugmented}
      startIcon={
        loadingOnClick && loading ? <CircularProgress size={12} /> : startIcon
      }
      disabled={disabled || (loadingOnClick && loading)}
    >
      {children}
    </Button>
  );
}
