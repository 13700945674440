'use client';

import { useLingui } from '@lingui/react';
import { Link as DesignSystemLink } from '@prismo-io/design-system';
import type { LanguageCodeT } from '@prismo-io/schemas';
import {
  type ComponentProps,
  type MouseEventHandler,
  useCallback,
} from 'react';
import type { Application } from '../types/application';
import type { AppPaths, LinkProps } from '../types/link';
import { translate } from '../utils/translate';

type Props<App extends Application, Path extends AppPaths<App>> = LinkProps<
  App,
  Path
> &
  Omit<ComponentProps<typeof DesignSystemLink>, 'href' | 'locale'> & {
    target?: string;
  };

export function Link<App extends Application, Path extends AppPaths<App>>(
  props: Props<App, Path>
) {
  const { i18n } = useLingui();

  const crtLocale = i18n.locale as LanguageCodeT;

  const {
    app,
    path,
    params = undefined,
    queryParams = undefined,
    addHostname = false,
    locale = crtLocale,
    onClick = undefined,
    children,
    ...rest
  } = props;

  const href = translate<App, Path>({
    app,
    path,
    params,
    queryParams,
    locale,
    addHostname,
  } as LinkProps<App, Path>);

  const onClickAugmented = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <DesignSystemLink {...rest} href={href} onClick={onClickAugmented}>
      {children}
    </DesignSystemLink>
  );
}
