'use client';

import { useLingui } from '@lingui/react';
import type { LanguageCodeT } from '@prismo-io/schemas';
import { useParams, usePathname } from 'next/navigation';
import { useMemo } from 'react';
import type { Application } from '../types';
import { decode } from '../utils/decode';

export const useDecodePathname = (app: Application) => {
  const { i18n } = useLingui();
  const pathname = usePathname();
  const params = useParams();

  return useMemo(() => {
    return decode({
      app,
      locale: i18n.locale as LanguageCodeT,
      pathname,
      params: params as Record<string, string>,
    });
  }, [app, pathname, params, i18n.locale]);
};
