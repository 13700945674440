'use client';

import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type Fn = () => unknown | Promise<unknown>;

type CtxT = {
  addFn: (fn: Fn) => void;
  removeFn: () => void;
  fn?: Fn | undefined;
};

const Ctx = createContext<CtxT | undefined>(undefined);

export const useMaybeRouterCtx = () => {
  return useContext(Ctx);
};

export const useRouterCtx = () => {
  const ctx = useContext(Ctx);

  if (!ctx) {
    throw new Error('useRouterCtx() must be called inside <RouterProvider />');
  }

  return ctx as CtxT;
};

export const RouterProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [fn, setFn] = useState<Fn | undefined>(undefined);

  const addFn = useCallback(
    (newFn: Fn) => {
      setFn(() => newFn);
    },
    [setFn]
  );
  const removeFn = useCallback(() => {
    setFn(() => undefined);
  }, [setFn]);

  return (
    <Ctx.Provider value={{ addFn, removeFn, fn }}>{children}</Ctx.Provider>
  );
};
